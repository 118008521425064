import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CardItem from "../components/carditem"

import PlayButton from "../components/playbutton"
import ChatPanel from "../components/chatpanel"

import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import CssBaseline from "@material-ui/core/CssBaseline"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Paper from "@material-ui/core/Paper"
import Img from "gatsby-image"

import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import IconButton from "@material-ui/core/IconButton"
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious"
import PlayArrowIcon from "@material-ui/icons/PlayArrow"
import SkipNextIcon from "@material-ui/icons/SkipNext"

import AniLink from "gatsby-plugin-transition-link/AniLink"

import AudioPlayer from "react-h5-audio-player"
import "react-h5-audio-player/lib/styles.css"

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    maxWidth: 345,
  },
  media: {
    height: "100%",
    paddingTop: 0, // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 151,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}))

export default function Component(props) {
  console.log("episode", props)
  const classes = useStyles()
  const theme = useTheme()
  const [expanded, setExpanded] = React.useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }
  var node = props.data.directusEpisode
  var duration = new Date(node.media_length * 1000).toISOString().substr(11, 8)
  return (
    <div>
      <SEO title={node.title} />

      <Breadcrumbs aria-label="breadcrumb">
        <AniLink cover direction="left" to="/" variant="body2">
          Home
        </AniLink>
        <AniLink cover direction="left" to="/shows" variant="body2">
          Shows
        </AniLink>
        <AniLink
          cover
          direction="left"
          to={`/shows/${node.podcast.slug}`}
          variant="body2"
        >
          {node.podcast.title}
        </AniLink>
        <Typography color="textPrimary">{node.title}</Typography>
      </Breadcrumbs>

      <Container component="main" maxWidth={false}>
        <CssBaseline />
        <div className={classes.paper}>
          <Paper elevation={3}>
            <PlayButton src={node.url} compact={false} />
          </Paper>

          <Card className={classes.root}>
            <div className={classes.details}>
              <CardContent className={classes.content}>
                <Typography component="h5" variant="h5">
                  {node.title}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  {node.subtitle.replace("<![CDATA[", "").replace("]]>", "") }
                </Typography>
                
              </CardContent>
              <div className={classes.controls}>
                <IconButton aria-label="previous">
                  {theme.direction === "rtl" ? (
                    <SkipNextIcon />
                  ) : (
                    <SkipPreviousIcon />
                  )}
                </IconButton>
                <IconButton aria-label="play/pause">
                  <PlayArrowIcon className={classes.playIcon} />
                </IconButton>
                <IconButton aria-label="next">
                  {theme.direction === "rtl" ? (
                    <SkipPreviousIcon />
                  ) : (
                    <SkipNextIcon />
                  )}
                </IconButton>
              </div>
            </div>
            <CardMedia
              className={classes.cover}
              image={node.image.localFile.url}
              title={node.title}
            />
          </Card>

                    <ChatPanel></ChatPanel>

        </div>
      </Container>
    </div>
  )
}

// This is the page query that connects the data to the actual component. Here you can query for any and all fields
// you need access to within your code. Again, since Gatsby always queries for `id` in the collection, you can use that
// to connect to this GraphQL query.

export const query = graphql`
  query($id: String) {
    directusEpisode(id: { eq: $id }) {
      title
      subtitle
      url
      slug
      media_type
      media_length
      link
      image {
        localFile {
          url
          childImageSharp {
            fluid(maxWidth: 200, maxHeight: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      podcast {
        title
        slug
        color
        image {
          localFile {
            url
            childImageSharp {
              fluid(maxWidth: 200, maxHeight: 200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      description
    }
  }
`
